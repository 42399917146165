<template>
  <main>
    <div class="container">
      <div class="row">
        <div class="col-12 m-0 no-max-w home-div">
          <div>
            <Card class="mb-4 pt-3">
              <template #title>
                <div class="me-3 d-flex align-items-center">
                  <button class="p-button-primary p-button btn d-flex justify-content-evenly gap-2" @click="goBack"><i class="fa-solid fa-left-to-line fw-bold"></i> <span class="fw-bold">Segments</span></button> <span> / <span class="fw-bold text-black"> {{ this.segments?.find(x => x.id == parseInt(this.$route.params.id)).name }}</span></span>
                </div>
              </template>
              <template #content>
                <div class="d-flex flex-column">
                  <div>
                    <div class="d-flex align-items-end w-100 gap-3">
                      <div class="w-100">
                        <label for="user-dropdown" class="form-label">Segment Key</label>
                        <select
                            id="segmentKey"
                            v-model="data.segmentKey"
                            class="form-control w-100">
                          <option v-for="option in segmentKeyEnum" :key="option.id" :value="option.id">
                            {{ option.name }}
                          </option>
                        </select>
                      </div>

                      <input type="text" style="padding-top: 15px !important;padding-left: 18px !important;padding-bottom: 15px !important;width: 50px" class="form-control" disabled placeholder="=" />


                      <div class="w-100">
                        <label for="user-id-input" class="form-label">Segment Value</label>
                        <input
                            id="segmentValue"
                            placeholder="Segment Value"
                            v-model="data.segmentValue"
                            type="text"
                            style="padding-top: 15px !important;padding-left: 18px !important;padding-bottom: 15px !important;"
                            class="form-control w-100" />
                      </div>


                    </div>

                    <div class="w-100 d-flex align-items-center mt-3 mb-3">
                      <label for="extra-credit-input" class="form-label">Is Active</label>
                      <input
                          id="isActive"
                          type="checkbox"
                          v-model="data.isActive"
                          class="form-switch ms-2" />

                    </div>


                    <button type="button" class="btn pb-5" @click="addProperty">+ Add property</button>


                    <div v-for="(item, index) in arrayData" :key="index" class="d-flex gap-2 mt-2 align-items-center">
                      <span>{{ item }}</span>
                      <button type="button" class="btn btn-danger" @click="removeFromArray(index)">Sil</button>
                    </div>
                  </div>

                  <div class="mt-3">
                    <button
                        class="p-button p-component p-button-info"
                        style="border-radius: 0.5em;"
                        @click="createObj"
                    >
                      Save
                    </button>
                  </div>


                </div>
              </template>
            </Card>

          </div>

          <div class="mb-3 mt-3">
            Total segment count: <span class="fw-bold">{{segmentCount}}</span>
          </div>

          <AstopiaTable ref="astopiaTable" :columns="columns" :url="'/admin/segment/segmentProperties-list/'"
                        :default-post-data="this.$route.params.id + '?test=test'"
                        :axiosDataName="'data'" :sort-field="'id'" :totalDataName="'total_records'"
                        @detail-see="detailSee" @delet-see="deleteSee" @add-button="addInAppMessage"  :addButton="false" :isOnlyNodata="true" :dataPagination="false" :postGetData="false" :goBackButton="false"/>

          <CreateModal
              :show="showCreateModal"
              modalTitle="Create property"
              modalWidth="50%"
              :formFields="[
                { label: 'Segment Key', type: 'select', model: 'segmentKey', options: segmentKeyEnum , id: 'id', value: 'name'},
                { label: 'Segment Value', type: 'text', model: 'segmentValue', required: true },
                { label: 'Is Active', type: 'switch', model: 'isActive', required: true },
              ]"
              @saveEndpoint="createObj"
              method="post"
              @hide="showCreateModal = false"
              @success="refreshTable"
          />

          <EditModal
              :show="showEditModal"
              modalTitle="Edit property"
              modalWidth="50%"
              :formFields="[
                { label: 'Segment Key', type: 'select', model: 'segmentKey', options: segmentKeyEnum , value: 'id', text: 'name'},
                { label: 'Segment Value', type: 'text', model: 'segmentValue', required: true },
              ]"
              :detailData="selectedObj"
              @saveEndpoint="editObj"
              method="put"
              :totalDataName="''"
              :isOnlyNodata="true"
              @hide="showEditModal = false"
          />

          <DeleteModal
              :show="showDeleteModal"
              :itemId="selectedDeleteId"
              modalTitle="Delete properties"
              deleteMessage="Are you sure you want to delete this segment property?"
              confirmButtonText="Yes"
              :deleteEndpoint="`/admin/segment/segmentProperties-delete/${selectedDeleteId}`"
              @update:isVisible="showDeleteModal = false"
              @delete-success="refreshTable"
          />

        </div>
      </div>
    </div>
  </main>
</template>
<script>
import AstopiaTable from "../../components/Tables/AstopiaTable.vue";
import {mapGetters} from "vuex";

export default {
  components: {AstopiaTable},
  data(){
    return {
      columns: [
        {field: "id", header: "Id", functionType: "none", selected: true},
        {field: "segmentId", header: "Segment Id", functionType: "none", selected: true},
        {field: "segmentKey", header: "Segment Key", functionType: "none", selected: true},
        {field: "segmentValue", header: "Segment Value", functionType: "none", selected: true},
        {field: "Actions", header: "Actions", functionType: "crud", edit: true, delete: true, selected: true},
      ],
      segmentKeyEnum: [
        {
          id: "DeviceOS",
          name: "DeviceOS",
        },
        {
          id: "HasReferred",
          name: "HasReferred",
        },
        {
          id: "MinAge",
          name: "MinAge",
        },
        {
          id: "MaxAge",
          name: "MaxAge",
        },
        {
          id: "Gender",
          name: "Gender",
        },
        {
          id: "IsPremium",
          name: "IsPremium",
        },
        {
          id: "BirthDateStart",
          name: "BirthDateStart",
        },
        {
          id: "BirthDateEnd",
          name: "BirthDateEnd",
        },
        {
          id: "BirthHour",
          name: "BirthHour",
        },
        {
          id: "BirthMinute",
          name: "BirthMinute",
        },
        {
          id: "RelationshipStatusList",
          name: "RelationshipStatusList",
        },
        {
          id: "SubscriptionPlanIds",
          name: "SubscriptionPlanIds",
        },
        {
          id: "OccupationId",
          name: "OccupationId",
        },
        {
          id: "SubscriptionStartedDate",
          name: "SubscriptionStartedDate",
        },
        {
          id: "SessionStart",
          name: "SessionStart",
        },
        {
          id: "SessionEnd",
          name: "SessionEnd",
        },
        {
          id: "SessionCount",
          name: "SessionCount",
        },
        {
          id: "RegisterDate",
          name: "RegisterDate",
        },
        {
          id: "IsRetro",
          name: "IsRetro",
        },
        {
          id: "Name",
          name: "Name",
        },
        {
          id: "RelationshipStatus",
          name: "RelationshipStatus",
        },
        {
          id: "Language",
          name: "Language",
        },
        {
          id: "AscendantSignId",
          name: "AscendantSignId",
        },
        {
          id: "FollowingId",
          name: "FollowingId",
        },
      ],
      data: {
        segmentId: 0,
        segmentKey: "",
        segmentValue: "",
        type: "",
        isActive: true
      },
      dataUpdate:[
        {
          "segmentId": 0,
          "segmentKey": "string",
          "segmentValue": "string",
          "type": "string",
          "isActive": true
        }
      ],
      showCreateModal: false,
      showEditModal: false,
      showDeleteModal: false,
      selectedDeleteId: null,
      selectedObjId: null,
      segments: null,
      selectedObj: null,
      dialogTypes: null,
      arrayData: [],
      segmentCount: null,
    }
  },
  computed: {
    ...mapGetters(['supportedLanguages']),
  },
  created() {
    this.$appAxios.get("/admin/segment/segment-list?limit=1000&offset=0").then((res) => {
      this.segments = res.data;
    });
    this.$appAxios.get(`/admin/segment/segment-count?segmentId=${parseInt(this.$route.params.id)}`).then((res) => {
      this.segmentCount = res.data.totalCount;
    });
    this.$appAxios.get("/admin/in-app-message/dialog-Types").then((res) => {
      this.dialogTypes = res.data;
    });
  },
  methods: {
    addInAppMessage() {
      this.showCreateModal = true
    },
    addProperty() {
        this.data.segmentId = parseInt(this.$route.params.id)
        this.data.type = "user"
        this.arrayData.push(this.data);
        this.data = {};
    },
    removeFromArray(index) {
      this.arrayData.splice(index, 1);
    },
    createObj() {
      this.$appAxios.post(`/admin/segment/segmentProperties-create?segmentId=${parseInt(this.$route.params.id)}`, this.arrayData).then(() => {
        this.refreshTable()
        this.showCreateModal = false
      }).catch(() => {
        this.showCreateModal = false
      })

      this.refreshTable()

    },
    async fetchSegmentList(selectedObjId) {
      try {
        const response = await this.$appAxios.get(`/admin/segment/segmentProperties-list/`+ this.$route.params.id + `?test=test&offset=0&limit=10`);
        this.segmentPropertiesList = response.data || []; // Listeyi kaydet

        this.selectedObj = this.segmentPropertiesList.find(item => item.id === selectedObjId) || null;
      } catch (error) {
        console.error("Segment listesi alınırken hata oluştu:", error);
      }
    },
    detailSee(obj) {
      this.selectedObjId = obj.id
      this.fetchSegmentList(this.selectedObjId)
      this.showEditModal = true
    },
    editObj(obj) {
      this.dataUpdate[0].segmentId = parseInt(this.$route.params.id);
      this.dataUpdate[0].segmentKey = obj?.segmentKey;
      this.dataUpdate[0].segmentValue = obj?.segmentValue;
      this.dataUpdate[0].type = "user";
      this.$appAxios.put(`/admin/segment/segmentPropertiesUpdate/${obj?.id}?segmentId=${this.dataUpdate[0].segmentId}`, this.dataUpdate).then(() => {
        this.refreshTable()
        this.showEditModal = false
      }).catch(err => {
        console.log(err)
        this.showEditModal = false
      })
      setTimeout(() => {
        this.showEditModal = false;
        this.refreshTable();
      }, 2000);
    },

    deleteSee(report) {
      this.selectedDeleteId = report.id
      this.showDeleteModal = true
    },
    refreshTable() {
      this.$refs.astopiaTable.getData();
    },
    refreshTable2() {
      this.$refs.astopiaTable.fetchData();
    },
    goBack() {
      this.$router.go(-1);
    },
  }
}
</script>

