<template>
  <Header/>
  <main>
    <div class="container">
      <div class="row">
        <div class="col-12 m-0 no-max-w home-div">
          <Toast/>
          <ConfirmDialog/>
          <div class="d-flex justify-content-around mb-3 mt-3">
            <Card style="width: 35rem;">
              <template #title>
                Test API
              </template>
              <template #content>
                <div class="row mb-3 mt-3">
                  <div class="col-md-6 form-group">
                    <label for="endpoint_dropdown" class="d-block">Endpoint</label>
                    <Dropdown id="endpoint_dropdown" class="mb-3" v-model="endpointModel.endpoint" :options="endpoints"
                              optionLabel="name" optionValue="name" placeholder="Select an Endpoint"/>
                  </div>
                  <div class="col-md-6 form-group">
                    <label for="date_picker" class="d-block">Date</label>
                    <Datepicker id="date_picker" class="mb-3" style="width: 200px;"
                                v-model="endpointModel.date"></Datepicker>
                  </div>
                </div>
                <div class="row mb-3 mt-3">
                  <div class="col-md-6 form-group">
                    <label for="city_picker" class="d-block">City</label>
                    <AutoComplete
                        v-model="selectedPlace"
                        :suggestions="places"
                        @complete="onPlaceInput"
                        field="display_name"
                        placeholder="Search for a location"
                        class="w-100"
                    />
                  </div>
                </div>
              </template>
              <template #footer>
                <Button icon="pi pi-cloud-download" @click="fetchAPI()" label="Fetch API"/>
              </template>
            </Card>
            <Card style="width: 45rem;">
              <template #title>
                Result
              </template>
              <template #content>
                <div v-if="APIResult == null">
                  <div v-for="i in 10" :key="i">
                    <Skeleton class="mb-3"/>
                  </div>
                </div>
                <VueJsonPretty
                    v-else
                    :data="APIResult"
                    :show-length="true"
                    :deep="2"
                    :highlight-mouseover-node="true"
                    :show-line="true"
                    :collapsed="false"
                />
              </template>

            </Card>
          </div>

        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Header from "@/components/Header.vue";
import Button from "primevue/button";
import dayjs from "dayjs";
import "@vuepic/vue-datepicker/dist/main.css";
import Card from 'primevue/card';
import Dropdown from "primevue/dropdown";
import Datepicker from "@vuepic/vue-datepicker";
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
import Skeleton from 'primevue/skeleton';

export default {
  name: "TestAstrologyAPI",
  components: {
    Header,
    Card,
    Button,
    Dropdown,
    Datepicker,
    VueJsonPretty,
    Skeleton,
  },
  data() {

    return {
      endpointModel: {},
      APIResult: "",
      endpoints: [
        {name: 'planets/tropical', code: 'planets/tropical'},
        {name: 'house_cusps/tropical', code: 'house_cusps/tropical'},
        {name: 'lunar_metrics', code: 'lunar_metrics'},
        // {name: 'general_sign_report/tropical', code: 'general_sign_report/tropical'},
        // {name: 'general_house_report/tropical', code: 'general_house_report/tropical'},
        // {name: 'personality_report/tropical', code: 'personality_report/tropical'},
        {name: 'general_ascendant_report', code: 'general_ascendant_report'},
        {name: 'natal_wheel_chart', code: 'natal_wheel_chart'},
        {name: 'western_horoscope', code: 'western_horoscope'},
        {name: 'astro_details', code: 'astro_details'},
        {name: 'general_ascendant_report', code: 'general_ascendant_report'},
        {name: 'life_forecast_report/tropical', code: 'life_forecast_report/tropical'},
      ],
      selectedPlace: null,
      places: [],
      debounceTimer: null,
    };
  },
  methods: {
    onPlaceInput(event) {
      const query = event.query;
      if (this.debounceTimer) {
        clearTimeout(this.debounceTimer);
      }
      if (query.length > 2) {
        this.debounceTimer = setTimeout(() => {
          this.searchPlaces(event);
        }, 300);
      } else {
        this.places = [];
      }
    },
    searchPlaces(event) {
      const query = event.query;
      this.$appAxios
          .get(`/global/v3/location/${query}`)
          .then(response => {
            this.places = response.data.response.placeList.result.map(place => ({
              id: place.place_id,
              display_name: place.display_name,
              lat: place.lat,
              lon: place.lon,
              type: place.type
            }));
          })
          .catch(err => {
            console.error("Error fetching places:", err);
          });
    },

    fetchAPI() {
      if (!this.endpointModel.endpoint) return;

/*      {
        "path": "string",
          "day": 0,
          "month": 0,
          "year": 0,
          "hour": 0,
          "min": 0,
          "longitude": 0,
          "latitude": 0,
          "tzone": 0
      }*/



      this.APIResult = null;
      var date = dayjs(this.endpointModel.date);
      this.$appAxios.post("astrologyAPI/test", {
        path: this.endpointModel.endpoint,
        year: date.year(),
        month: date.month() + 1,
        day: date.date(),
        hour: date.hour(),
        min: date.minute(),
        longitude: this.selectedPlace.lon,
        latitude: this.selectedPlace.lat,
      })
          .then(resp => {

            if(typeof resp.data === 'string'){
              this.APIResult = JSON.parse(resp.data)
            }else {
              this.APIResult = resp.data
            }


          })
          .catch(err => {
            this.APIResult = err.response
          });
    }
  },
};
</script>
<style scoped>
.p-button-sm {
  height: 35px;
  width: 35px;
  font-size: 0.875rem;
  padding: 0.7rem;
}

.p-disabled,
.p-component:disabled {
  opacity: 0.8;
}

.p-dropdown {
  min-width: 200px;
}

.vue-json-pretty {
  font-family: monospace;
  background: #282c34;
  color: white;
  padding: 15px;
  border-radius: 8px;
  overflow-x: auto;
}


</style>
  