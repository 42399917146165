<template>
  <main>
    <div class="container">
      <div class="row">
        <div class="col-12 m-0 no-max-w home-div">
          <AstopiaTable ref="astopiaTable" :columns="columns" :url="'/admin/Survey/surveys/get-all'"
                        :default-post-data="'?test=test'"
                        :axiosDataName="'surveys'" :sort-field="'id'" :totalDataName="'totalCount'"
                        @detail-see="detailSee" @delet-see="deleteSee" @add-button="addInAppMessage"  :addButton="'Add Survey'" :isOnlyResponsedata="true" :dataPagination="true"/>



          <CreateModal
              :show="showCreateModal"
              modalTitle="Create survey"
              modalWidth="50%"
              :formFields="[
                { label: 'Title', type: 'text', model: 'title', placeholder: 'Enter title', required: false },
                { label: 'Description', type: 'text', model: 'description', required: false },
                { label: 'Is Multi Answer', type: 'switch', model: 'isMultiAnswer', required: false },
                { label: 'Is Premium', type: 'switch', model: 'isPremium', required: false },
                { label: 'Options', type: 'properties', model: 'properties', placeholder: 'Enter option', required: true },
              ]"
              @saveEndpoint="createObj"
              method="post"
              @hide="showCreateModal = false"
              @success="refreshTable"
          />

          <EditModal
              :show="showEditModal"
              modalTitle="Edit survey"
              modalWidth="50%"
              :formFields="[
                { label: 'Title', type: 'text', model: 'title', placeholder: 'Enter type', required: false },
                { label: 'Description', type: 'text', model: 'description', required: false },
                { label: 'Is Multi Answer', type: 'switch', model: 'isMultiAnswer', required: false },
                { label: 'Is Premium', type: 'switch', model: 'isPremium', required: false },
                { label: 'Options', type: 'properties', model: 'options', placeholder: 'Enter option', required: true },
              ]"
              :detailEndpoint="`/admin/Survey/survey/GetById/${selectedObjId}`"
              @saveEndpoint="editObj"
              method="put"
              :totalDataName="''"
              :isOnlyNodata="true"
              @hide="showEditModal = false"
          />


          <DeleteModal
              :show="showDeleteModal"
              :itemId="selectedDeleteId"
              modalTitle="Delete survey"
              deleteMessage="Are you sure you want to delete this survey?"
              confirmButtonText="Yes"
              :deleteEndpoint="`/admin/Survey/survey/delete/${selectedDeleteId}`"
              @update:isVisible="showDeleteModal = false"
              @delete-success="refreshTable"
          />

        </div>
      </div>
    </div>
  </main>
</template>
<script>
import AstopiaTable from "../../components/Tables/AstopiaTable.vue";
import {mapGetters} from "vuex";

export default {
  components: {AstopiaTable},
  data(){
    return {
      columns: [
        {field: "title", header: "Title", functionType: "none", edit: true, delete: true, selected: true},
        {field: "description", header: "Description", functionType: "none", selected: true},
        {field: "isMultiAnswer", header: "Is Multi Answer", functionType: "bool", selected: true},
        {field: "isPremium", header: "Is Premium", functionType: "bool", selected: true},
        {field: "options", header: "Options", functionType: "json", selected: true},
        {field: "Actions", header: "Actions", functionType: "crud", edit: true, delete: true, selected: true},
      ],
      data:{
        "title": "string",
        "description": "string",
        "isMultiAnswer": true,
        "isPremium": true,
        "options": [
          "string"
        ]
      },
      dataUpdate:{
        "title": "string",
        "description": "string",
        "isMultiAnswer": true,
        "isPremium": true,
        "options": [
          "string"
        ]
      },
      showModalCrud: false,
      showCreateModal: false,
      showEditModal: false,
      showDeleteModal: false,
      selectedDeleteId: null,
      selectedObjId: null,
      dialogTypes: null
    }
  },
  computed: {
    ...mapGetters(['supportedLanguages']),
  },
  created() {
    this.$appAxios.get("/admin/in-app-message/dialog-types").then((res) => {
      this.dialogTypes = res.data;
    });
  },
  methods: {
    addInAppMessage() {
      this.showCreateModal = true
    },
    createObj(obj) {
      this.data.title = obj.title;
      this.data.description = obj.description;
      this.data.isMultiAnswer = obj.isMultiAnswer;
      this.data.isPremium = obj?.isPremium;
      this.data.options = obj?.properties;
      this.$appAxios.post("/admin/Survey/survey/create", this.data).then(() => {
        this.refreshTable()
        this.showCreateModal = false
      }).catch(() => {
        this.showCreateModal = false
      })
    },
    detailSee(obj){
      this.selectedObjId = obj.id
      this.showEditModal = true
    },
    editObj(obj) {
      this.dataUpdate.title = obj.title;
      this.dataUpdate.description = obj.description;
      this.dataUpdate.isMultiAnswer = obj.isMultiAnswer;
      this.dataUpdate.isPremium = obj.isPremium;
      this.dataUpdate.options = obj?.options;
      this.$appAxios.put(`/admin/Survey/survey/update/${this.selectedObjId}`, this.dataUpdate).then(() => {
        this.refreshTable()
        this.showEditModal = false
      }).catch(err => {
        console.log(err)
        this.showEditModal = false
      })
    },
    getLookups(){
      this.showModalCrud = true
    },
    deleteSee(report) {
      this.selectedDeleteId = report.id
      this.showDeleteModal = true
    },
    refreshTable() {
      this.$refs.astopiaTable.getData();
    }
  }
}
</script>

