<template>
    <div class="container d-flex justify-content-evenly" style="padding-top: 100px;">
      <Card class="d-flex flex-column">
        <template #title>
          1. Upload template
        </template>
        <template #content>
          <div class="d-flex">
            <a href="https://docs.google.com/spreadsheets/d/13GanQsXMAC4Luvv0i0hCHZsRRk4UKp6sG8ZWDTqPK1s/edit?gid=0#gid=0" download="Ornek_Excel.xlsx" target="_blank">
              <button class="p-button p-component p-button-info d-inline me-2" style="border-radius: 0.5em;">Sample Excel</button>
            </a>
            <FileUpload
                mode="basic"
                class="ms-2"
                name="file[]"
                accept=".csv"
                :file-limit="1"
                :customUpload="true"
                @uploader="(event) => uploader(event, 'template')"
                :maxFileSize="5000000"
                choose-label="Upload"
                upload-label="Upload"
                cancel-label="Cancel"
            />
          </div>
        </template>
      </Card>
      <Card class="d-flex flex-column">
        <template #title>
         2. Upload contents
        </template>
        <template #content>
          <div class="d-flex">
            <a href="https://docs.google.com/spreadsheets/d/13GanQsXMAC4Luvv0i0hCHZsRRk4UKp6sG8ZWDTqPK1s/edit?gid=1607396448#gid=1607396448" download="Ornek_Excel.xlsx" target="_blank">
              <button class="p-button p-component p-button-info d-inline me-2"  style="border-radius: 0.5em;">Sample Excel</button>
            </a>
            <FileUpload
                mode="basic"
                class="ms-2"
                name="file[]"
                accept=".csv"
                :file-limit="1"
                :customUpload="true"
                @uploader="(event) => uploader(event, 'contents')"
                :maxFileSize="5000000"
                choose-label="Upload"
                upload-label="Upload"
                cancel-label="Cancel"
            />
          </div>
        </template>
      </Card>
    </div>
</template>

<script>
import Card from "primevue/card";

export default {
  components: {Card},
  data(){
    return {

    }
  },
  methods:{
    uploader(event, type) {
      const file = event.files[0];
      let formData = new FormData();
      formData.append("file", file);

      let endpoint = '';
      switch (type) {
        case 'template':
          endpoint = "admin/preview-story/upload-template";
          break;
        case 'contents':
          endpoint = "admin/preview-story/upload-contents";
          break;
        default:
          return;
      }
      this.$appAxios
          .post(endpoint, formData)
          .then(() => {
            this.$toast.add({
              severity: 'success',
              summary: 'Success',
              detail: `${type.charAt(0).toUpperCase() + type.slice(1)} file was uploaded successfully`,
              life: 3000
            });
            this.$swal.fire({
              text: "Dosya başarıyla yüklendi ve veriler eklendi.",
              icon: "success",
              customClass: {
                container: "my-sweet",
              },
              confirmButtonText: this.$t("global.ok"),
            });
          })
          .catch(() => {
            this.$toast.add({
              severity: 'error',
              summary: 'Error',
              detail: `Could not upload the ${type} file`,
              life: 3000
            });
          });
    }
  }
}
</script>