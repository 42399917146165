<template>
  <div class="container-card">
    <Card class="mb-4 pt-5">
      <template #title>
        Give extra credit
      </template>
      <template #content>
        <div class="d-flex flex-column">
          <label for="user-dropdown" class="form-label">Select User</label>
          <Dropdown
              id="user-dropdown"
              :options="content"
              optionLabel="fullName"
              optionValue="userId"
              v-model="selectedUserId"
              class="mb-3 w-100"
              placeholder="Select a user"
          >
            <!-- Search Input in Dropdown Header -->
            <template #header>
            <span class="p-input-icon-left w-100">
              <i class="pi pi-search"></i>
              <InputText
                  v-model="filters['global'].value"
                  placeholder="Search by name or ID"
                  class="w-100"
                  @change="getContent"
              />
            </span>
            </template>
          </Dropdown>

          <!-- Input for entering userId directly -->
          <label for="user-id-input" class="form-label">Or Enter User ID</label>
          <InputText
              id="user-id-input"
              v-model="selectedUserId"
              placeholder="Enter User ID"
              class="mb-3"
          />

          <!-- Input for extra credit -->
          <label for="extra-credit-input" class="form-label">Extra Credit</label>
          <InputNumber
              id="extra-credit-input"
              v-model="extraCredit"
              mode="decimal"
              placeholder="Enter extra credit"
              class="mb-3"
          />

          <!-- Submit Button -->
          <button
              class="p-button p-component p-button-info"
              style="border-radius: 0.5em;"
              @click="submitData"
          >
            Submit
          </button>

        </div>
      </template>
    </Card>
  </div>
</template>

<script>
import Card from "primevue/card";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import InputText from "primevue/inputtext";

export default {
  components: {
    Card,
    DataTable,
    Column,
    InputText,
  },
  data() {
    return {
      content: [], // Table data
      totalRecords: 0, // Total number of records for pagination
      loading: false, // Loading state for the table
      selectedUserId: null,
      extraCredit: 1,
      contentLazyParams: {
        first: 0,
        rows: 10,
        sortField: null,
        sortOrder: null,
        filters: {}, // Default filters for the API
      },
      filters: {
        global: { value: "" }, // Global search filter
      },
    };
  },
  methods: {
    getContent() {
      this.loading = true;

      // Extract filters from the component's lazy params
      const filters = {};
      for (const [key] of Object.entries(this.filters)) {
        filters[key] = this.filters[key].value || null;
      }

      // API request to fetch user data
      this.$appAxios
          .post("admin/user/list", {
            first: this.contentLazyParams.first,
            rows: this.contentLazyParams.rows,
            sortField: this.contentLazyParams.sortField,
            sortOrder: this.contentLazyParams.sortOrder,
            page: Math.floor(this.contentLazyParams.first / this.contentLazyParams.rows) + 1,
            filters: JSON.stringify(filters), // Send filters as stringified JSON
          })
          .then((response) => {
            this.content = response.data.data.map((user) => ({
              userId: user.userId,
              fullName: `${user.name} ${user.surname}`,
              email: user.email,
            }));
            this.totalRecords = response.data.totalRecords;
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
    },
    submitData() {
      const payload = {
        userId: this.selectedUserId,
        extraCredit: this.extraCredit,
      };
      this.$appAxios.post("/admin/celestia/give-extra-credit", payload).then(() => {
        this.$swal.fire({
              text: "Başarıyla gerçekleşti ve veriler güncellendi.",
              icon: "success",
              customClass: {
                container: "my-sweet",
              },
              confirmButtonText: this.$t("global.ok"),
              allowOutsideClick: true,
            })
      }).catch(() => {
        this.$swal.fire({
          text: "İşlem sırasında bir hata oluştu.",
          icon: "error",
          customClass: {
            container: "my-sweet",
          },
          confirmButtonText: this.$t("global.ok"),
        });
      })
    },
  },
  mounted() {
    this.getContent(); // Fetch initial data on mount
  },
};
</script>

<style>

.container-card{
  display: flex;
  justify-content: center;
  width: 100%;
}

.container-card > .p-card {
  max-width: 700px !important;
  margin-top: 150px;
  width: 100%;
}
</style>
