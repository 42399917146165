<template>
  <main>
    <div class="container">
      <div class="row">
        <div class="col-12 m-0 no-max-w home-div">
          <AstopiaTable ref="astopiaTable" :columns="columns" :url="'/admin/splash/list'"
                        :default-post-data="'?test=test'"
                        :axiosDataName="'data'" :sort-field="'id'" :totalDataName="'totalRecords'"
                        @detail-see="detailSee" @add-button="addObj"  @delet-see="deleteSee" :addButton="true" @language-modal="showEditLanguage" :dataPagination="true" @upload-button="uploadObj" :uploadButton="true"/>

          <CreateModal
              :show="showCreateModal"
              modalTitle="Create Splash"
              modalWidth="50%"
              :formFields="[
                { label: 'Language', type: 'select', model: 'language', placeholder: 'Enter Language', options: supportedLanguages , id: 'id', value: 'name' },
                { label: 'Gender', type: 'select', model: 'gender', options: [{ 'id': 0, 'value': 'All' }, { 'id': 1, 'value': 'Man' }, { 'id': 2, 'value': 'Woman' }], id: 'id', value: 'value', required: true},
                { label: 'Is General', type: 'switch', model: 'isGeneral', required: false },
                { label: 'Ascendant Sign Id', type: 'select', model: 'ascendantSignId', options: signs, id: 'id', value: 'name', required: true},
                { label: 'Text', type: 'text', model: 'text', required: false },
              ]"
              @saveEndpoint="createObj"
              method="post"
              @hide="showCreateModal = false"
              @success="refreshTable"
          />


          <EditModal
              :show="showEditModal"
              modalTitle="Edit Splash"
              modalWidth="50%"
              :formFields="[
                { label: 'Gender', type: 'select', model: 'gender', options: [{ 'id': 0, 'value': 'All' }, { 'id': 1, 'value': 'Man' }, { 'id': 2, 'value': 'Woman' }], value: 'id', text: 'value', required: true},
                { label: 'isGeneral', type: 'switch', model: 'isGeneral', required: false },
                { label: 'Ascendant Sign Id', type: 'select', model: 'ascendantSignId', options: signs, value: 'id', text: 'name', required: true},
                { label: 'isPremium', type: 'switch', model: 'isPremium', required: false },

              ]"
              :detailEndpoint="`/admin/splash/${selectedObjId}`"
              @saveEndpoint="editObj"
              method="put"
              :totalDataName="''"
              :isOnlyNodata="true"
              @hide="showEditModal = false"
          />

          <TranslationsModal
              :show="showEditLanguageModal"
              @hide="showEditLanguageModal = false"
              :contentId="editTranslationContentId"
              :type="'splash'"
          />

          <DeleteModal
              :show="showDeleteModal"
              :itemId="selectedDeleteObj"
              modalTitle="Delete Splash"
              deleteMessage="Are you sure you want to delete this splash?"
              confirmButtonText="Yes"
              :deleteEndpoint="`/admin/splash/${selectedDeleteId}`"
              @update:isVisible="showDeleteModal = false"
              @delete-success="refreshTable"
          />

        </div>
      </div>
    </div>
  </main>
</template>
<script>
import AstopiaTable from "../../components/Tables/AstopiaTable.vue";
import {mapGetters} from "vuex";
import TranslationsModal from "../Modals/TranslationsModal.vue";

export default {
  components: {TranslationsModal, AstopiaTable},
  data(){
    return {
      columns: [
        {field: "gender", header: "Gender", functionType: "none", selected: true},
        {field: "ascendantSignId", header: "Ascendant Sign", functionType: "none", selected: true},
        {field: "isGeneral", header: "Is General", functionType: "bool", selected: true},
        {field: "createdDate", header: "Created Date", functionType: "Date", selected: true},
        {field: "lastUpdateDate", header: "Last Update Date", functionType: "Date", selected: true},
        {field: "language", header: "Language", functionType: "none", selected: true},
        {field: "Actions", header: "Actions", functionType: "crud", created: false, edit: false, delete: true, translations: true, selected: true},
      ],
      data:{
        "id": 0,
        "gender": 0,
        "isGeneral": true,
        "ascendantSignId": 0,
        "language": "",
        "isPremium": true,
        "text": "",
        "autoTranslate": true
      },
      dataUpdate:{
        "id": 0,
        "gender": 0,
        "isGeneral": true,
        "ascendantSignId": 0,
        "language": "",
        "isPremium": true,
        "text": "",
        "autoTranslate": true
      },
      showModalCrud: false,
      showCreateModal: false,
      showEditModal: false,
      showDeleteModal: false,
      selectedDeleteId: null,
      selectedObjId: null,
      dialogTypes: null,
      showEditLanguageModal: false,
      editTranslationContentId: 0,
      signs: [],
    }
  },
  computed: {
    ...mapGetters(['supportedLanguages']),
  },
  created() {
    this.$appAxios.get("/admin/in-app-message/dialog-types").then((res) => {
      this.dialogTypes = res.data;
    });
  },
  mounted() {
    this.$appAxios
        .get("admin/global/signs")
        .then((resp) => {
          this.signs = resp.data;
        });
  },
  methods: {
    addObj() {
      this.showCreateModal = true
    },
    createObj(obj) {
      if (obj.isGeneral === false) {
        this.data.gender = obj.gender;
        this.data.ascendantSignId = obj.ascendantSignId;
      }
      this.data.isGeneral = obj.isGeneral;
      this.data.language = obj.language;
      this.data.isPremium = obj.isPremium;
      this.data.text = obj.text;
      this.data.autoTranslate = true;
      this.$appAxios.post("/admin/splash", this.data).then(() => {
        this.refreshTable()
        this.showCreateModal = false
      }).catch(() => {
        this.showCreateModal = false
      })
    },
    detailSee(obj){
      this.selectedObjId = obj.id
      this.showEditModal = true
    },
    detailSee2(obj){
      this.$router.push({name:"WhatWeDoTranslations", params:{id:obj.id}})
    },
    editObj(obj) {
      this.dataUpdate.gender = obj.gender;
      this.dataUpdate.isGeneral = obj.isGeneral;
      this.dataUpdate.ascendantSignId = Number(obj.ascendantSignId);
      this.dataUpdate.isPremium = obj.isPremium;

      this.$appAxios.put("/admin/splash", this.dataUpdate).then(() => {
        this.refreshTable()
        this.showEditModal = false
      }).catch(() => {
        this.showEditModal = false
      })
    },
    showEditLanguage(obj) {
      this.editTranslationContentId = obj.id;
      this.showEditLanguageModal = true;
    },
    getLookups(){
      this.showModalCrud = true
    },
    deleteSee(report) {
      this.selectedDeleteId = report.id
      this.showDeleteModal = true
    },
    refreshTable() {
      this.$refs.astopiaTable.getData();
    },
    async uploadObj(event){
      const formData = new FormData();
      formData.append("file", event.files[0]);

      try {
        await this.$appAxios.post("/admin/splash/add-splash-text", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        this.$swal
            .fire({
              text: "Dosya başarıyla yüklendi ve veriler eklendi.",
              icon: "success",
              customClass: {
                container: "my-sweet",
              },
              confirmButtonText: this.$t("global.ok"),
              allowOutsideClick: true,
            })
            .then(() => {
              setTimeout(() => {
                this.refresh();
              }, 5000);
            })
            .finally(() => {
              setTimeout(() => {
                this.refresh();
              }, 5000);
            });
      } catch (error) {
        this.$swal.fire({
          text: "Dosya yüklenirken bir hata oluştu.",
          icon: "error",
          customClass: {
            container: "my-sweet",
          },
          confirmButtonText: this.$t("global.ok"),
        });
        console.error("File upload error:", error);
      }
    },
  }
}
</script>

