<template>
  <Header/>
  <main>
    <div class="container">
      <div class="row">
        <div class="col-12 m-0 no-max-w home-div">
          <Toast/>

          <AstopiaTable ref="astopiaTable" :columns="columns" :url="'/admin/segment/segment-list'"
                        :default-post-data="'?limit=1000&offset=0&test=test'"
                        :axiosDataName="'data'" :sort-field="''" :totalDataName="'totalRecords'"
                        @detail-see="detailSee" @detail-see2="detailSee2" @delet-see="deleteSee" @add-button="addPublicFigure" :addButton="'Create segment'" :dataPagination="false" :isOnlyNodata="true" :optionsList="optionsList" :selectedObj3="false"/>

          <EditModal
              :show="showEditModal"
              modalTitle="Edit segment"
              modalWidth="50%"
              :formFields="[
                { label: 'Name', type: 'text', model: 'name', placeholder: 'Enter Name', required: true },
                { label: 'Description', type: 'text', model: 'description', placeholder: 'Enter Description', required: true },
                { label: 'Languages', type: 'multi', model: 'languages', options: optionsList, value: 'id', text: 'name', required: true},
                { label: 'isActive', type: 'switch', model: 'isActive', required: true },
              ]"
              :detailEndpoint="`/admin/segment/segment-getById/${selectedObjId}`"
              @saveEndpoint="editObj"
              method="put"
              :totalDataName="'data'"
              :isOnlyNodata="true"
              @hide="showEditModal = false"
          />

          <CreateModal
              :show="showCreateModal"
              modalTitle="Create segment"
              modalWidth="50%"
              :formFields="[
                { label: 'Name', type: 'text', model: 'name', placeholder: 'Enter Name', required: true },
                { label: 'Description', type: 'text', model: 'description', placeholder: 'Enter Description', required: true },
                { label: 'Languages', type: 'multi', model: 'languages', options: optionsList, id: 'id', value: 'name', required: true},
                { label: 'isActive', type: 'switch', model: 'isActive', required: true },
              ]"
              @saveEndpoint="createObj"
              method="post"
              @hide="showCreateModal = false"
              @success="refreshTable"
          />



          <DeleteModal
              :show="showDeleteModal"
              :itemId="selectedDeleteId"
              modalTitle="Delete question"
              deleteMessage="Are you sure you want to delete this segment?"
              confirmButtonText="Yes"
              :deleteEndpoint="`/admin/segment/segment-delete/${selectedDeleteId}`"
              @update:isVisible="showDeleteModal = false"
              @delete-success="refreshTable"
          />
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Header from "@/components/Header.vue";
import AstopiaTable from "@/components/Tables/AstopiaTable.vue";
import dayjs from "dayjs";
import "@vuepic/vue-datepicker/dist/main.css";
import Toast from 'primevue/toast';
import {mapGetters} from "vuex";
import dist from "vue-sweetalert2/dist";

var utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

export default {
  name: "Users",
  components: {
    Header,
    Toast,
    AstopiaTable
  },
  data() {
    const schema = {
      price: "required|numeric|regex",
    };

    return {
      loading: false,
      totalRecords: 0,
      content: [],
      filters: {
        global: {value: null},
        name: {value: null},
        isPublicFigure: {value: true}
      },
      contentLazyParams: {},
      schema,
      houses: [],
      signs: [],
      genders: [{id: 1, name: "Man"}, {id: 2, name: "Woman"}, {id: 3, name: "Other"}],
      showEditBirthdateModal: false,
      selectedObjId: null,
      showEditModal: false,
      showCreateModal: false,
      showDeleteModal: false,
      selectedDeleteId: null,
      editUserModel: {},
      messages: [],
      messageCount: 0,
      saveButtonLoading: false,
      showPublicFigureModal: false,
      newPublicFigureModel: {},
      languages: [],
      countries: [],
      cities: [],
      states: [],
      optionsList: [{id: "false", name: "App"},{id: "true", name: "Web"}],
      columns: [
        {field: "Detail",header:"Detail", functionType: "detail", selected: true},
        {field: "id", header: "Id", functionType: "none", selected: true},
        {field: "name", header: "Name", functionType: "none", selected: true},
        {field: "languages", header: "Languages", functionType: "none", selected: true},
        {field: "isActive", header: "Is Active", functionType: "bool", selected: true},
        {field: "properties", header: "Properties", functionType: "json", selected: true},
        {field: "Actions", header: "Actions", functionType: "crud", edit: true, delete: true, selected: true},
      ],
      data:{
        "name": "",
        "description": "",
        "languages": [
          ""
        ],
        "isActive": true
      },
      dataUpdate:{
        "name": "",
        "description": "",
        "languages": [
          ""
        ],
        "isActive": true
      }
    };
  },
  mounted() {
    this.$appAxios.get("admin/app-settings/language/list?test=test&offset=0&limit=10000")
        .then(resp => {
          this.languages = resp.data.languages
        })
        .catch(err => {
          console.log(err)
        });
    this.$appAxios.get("admin/global/supported-languages")
        .then(resp => {
          this.optionsList = resp.data
        })
        .catch(err => {
          console.log(err)
        });
  },
  computed: {
    dist() {
      return dist
    },
    ...mapGetters(['occupations', 'signs', 'houses', 'relationships', 'occupationIds', 'relationshipIds']),
  },
  methods: {
    openEditModal(rowData) {
      this.editUserModel = rowData;
      this.showEditBirthdateModal = true;
    },
    deleteSee(report) {
      this.selectedDeleteId = report.id

      this.showDeleteModal = true
    },
    detailSee(obj) {
      this.selectedObjId = obj.id
      this.showEditModal = true
    },
    detailSee2(obj){
      this.$router.push({name:"SegmentProperties", params:{id:obj.id}})
    },
    editObj(obj) {
      this.dataUpdate.name = obj.name;
      this.dataUpdate.description = obj.description;
      this.dataUpdate.languages = obj.languages;
      this.dataUpdate.isActive = obj.isActive;
      /*      const headers = {
              "Accept-Language": obj?.language,
            };*/

      this.$appAxios.put(`/admin/segment/segment-update/${this.selectedObjId}`, this.dataUpdate).then(() => {

        this.refreshTable()
        this.showEditModal = false
      }).catch(err => {
        console.log(err)
        this.showEditModal = false
      })
    },
    createObj(obj) {
      this.data.name = obj.name;
      this.data.description = obj.description;
      this.data.languages = obj.languages;
      this.data.isActive = obj.isActive;
/*      const headers = {
        "Accept-Language": obj?.language,
      };*/

      this.$appAxios.post("/admin/segment/segment-create", this.data).then(() => {
        this.refreshTable()
        this.showCreateModal = false
      }).catch(() => {
        this.showCreateModal = false
      })
    },
    addPublicFigure() {
      this.showCreateModal = true
    },
    refreshTable() {
      this.$refs.astopiaTable.getData();
      this.$refs.astopiaTable.getObj();
    }
  },
};
</script>
<style scoped>
.p-button-sm {
  height: 35px;
  width: 35px;
  font-size: 0.875rem;
  padding: 0.7rem;
}

.p-disabled,
.p-component:disabled {
  opacity: 0.8;
}

.p-dropdown {
  min-width: 200px;
}
</style>