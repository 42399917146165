<template>
  <Header/>
  <main>
    <div class="container">
      <div class="row">
        <div class="col-12 m-0 no-max-w home-div">
          <Toast/>

          <AstopiaTable ref="astopiaTable" :columns="columns" :url="'/admin/question-templates/list'"
                        :default-post-data="'?test=test'"
                        :axiosDataName="'data'" :sort-field="''" :totalDataName="'totalRecords'"
                        @detail-see="detailSee" @delet-see="deleteSee" @add-button="addPublicFigure" :addButton="true" :dataPagination="false" :isOnlyNodata="true" :optionsList="optionsList" :selectedObj3="true"/>

          <EditModal
              :show="showEditModal"
              modalTitle="Edit question"
              modalWidth="50%"
              :formFields="[
                { label: 'Language', type: 'select', model: 'language', options: [{'value': 'en', 'text': 'English' }, { 'value': 'tr', 'text': 'Türkçe' }], value: 'value', text: 'text', required: true},
                { label: 'Question', type: 'text', model: 'question', placeholder: 'Enter question', required: true },
              ]"
              :detailEndpoint="`/admin/questiontemplates/get/${selectedObjId}`"
              @saveEndpoint="editObj"
              method="put"
              :totalDataName="'data'"
              :isOnlyNodata="true"
              @hide="showEditModal = false"
          />


          <CreateModal
              :show="showCreateModal"
              modalTitle="Create question"
              modalWidth="50%"
              :formFields="[
                { label: 'Language', type: 'select', model: 'language', options: [{'id': 'en', 'name': 'English' }, { 'id': 'tr', 'name': 'Türkçe' }], id: 'id', value: 'name', required: true},
                { label: 'Question', type: 'text', model: 'question', placeholder: 'Enter question', required: true },
              ]"
              @saveEndpoint="createObj"
              method="post"
              @hide="showCreateModal = false"
              @success="refreshTable"
          />



          <DeleteModal
              :show="showDeleteModal"
              :itemId="selectedDeleteId"
              modalTitle="Delete question"
              deleteMessage="Are you sure you want to delete this question?"
              confirmButtonText="Yes"
              :deleteEndpoint="`/admin/questiontemplates/delete/${selectedDeleteId}`"
              @update:isVisible="showDeleteModal = false"
              @delete-success="refreshTable"
          />
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import Header from "@/components/Header.vue";
import AstopiaTable from "@/components/Tables/AstopiaTable.vue";
import dayjs from "dayjs";
import "@vuepic/vue-datepicker/dist/main.css";
import Toast from 'primevue/toast';
import {mapGetters} from "vuex";
import dist from "vue-sweetalert2/dist";

var utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

export default {
  name: "Users",
  components: {
    Header,
    Toast,
    AstopiaTable
  },
  data() {
    const schema = {
      price: "required|numeric|regex",
    };

    return {
      loading: false,
      totalRecords: 0,
      content: [],
      filters: {
        global: {value: null},
        name: {value: null},
        isPublicFigure: {value: true}
      },
      contentLazyParams: {},
      schema,
      houses: [],
      signs: [],
      genders: [{id: 1, name: "Man"}, {id: 2, name: "Woman"}, {id: 3, name: "Other"}],
      showEditBirthdateModal: false,
      selectedObjId: null,
      showEditModal: false,
      showCreateModal: false,
      showDeleteModal: false,
      selectedDeleteId: null,
      editUserModel: {},
      messages: [],
      messageCount: 0,
      saveButtonLoading: false,
      showPublicFigureModal: false,
      newPublicFigureModel: {},
      languages: [],
      countries: [],
      cities: [],
      states: [],
      optionsList: [{id: "false", name: "App"},{id: "true", name: "Web"}],
      columns: [
        // {field: "Detail",header:"Detail", functionType: "detail", selected: true},
        {field: "id", header: "Id", functionType: "none", selected: true},
        {field: "question", header: "Question", functionType: "none", selected: true},
        {field: "language", header: "Language", functionType: "none", selected: true},
        {field: "Actions", header: "Actions", functionType: "crud", edit: true, delete: true, selected: true},
      ],
      data:{
        "question": "string",
        "language": "string"
      },
      dataUpdate:{
        "question": "string",
        "language": "string"
      }
    };
  },
  mounted() {
    this.$appAxios.get("admin/app-settings/language/list?test=test&offset=0&limit=10000")
        .then(resp => {
          this.languages = resp.data.languages
        })
        .catch(err => {
          console.log(err)
        });
    this.$appAxios.get("admin/global/supported-languages")
        .then(resp => {
          this.optionsList = resp.data
        })
        .catch(err => {
          console.log(err)
        });
  },
  computed: {
    dist() {
      return dist
    },
    ...mapGetters(['occupations', 'signs', 'houses', 'relationships', 'occupationIds', 'relationshipIds']),
  },
  methods: {
    openEditModal(rowData) {
      this.editUserModel = rowData;
      this.showEditBirthdateModal = true;
    },
    deleteSee(report) {
      this.selectedDeleteId = report.id

      this.showDeleteModal = true
    },
    detailSee(obj) {
      this.selectedObjId = obj.id
      this.showEditModal = true
    },
    editObj(obj) {
      this.dataUpdate.question = obj.question;
      this.dataUpdate.language = obj.language;

      const headers = {
        "Accept-Language": obj?.language,
      };

      this.$appAxios.put(`/admin/questiontemplates/update/${this.selectedObjId}`, this.dataUpdate, { headers }).then(() => {

        this.refreshTable()
        this.showEditModal = false
      }).catch(err => {
        console.log(err)
        this.showEditModal = false
      })
    },
    createObj(obj) {
      this.data.question = obj.question;
      this.data.language = obj.language;

      const headers = {
        "Accept-Language": obj?.language,
      };

      this.$appAxios.post("/admin/questiontemplates/create", this.data, {headers}).then(() => {
        this.refreshTable()
        this.showCreateModal = false
      }).catch(() => {
        this.showCreateModal = false
      })
    },
    addPublicFigure() {
      this.showCreateModal = true
    },
    refreshTable() {
      this.$refs.astopiaTable.getData();
      this.$refs.astopiaTable.getObj();
    }
  },
};
</script>
<style scoped>
.p-button-sm {
  height: 35px;
  width: 35px;
  font-size: 0.875rem;
  padding: 0.7rem;
}

.p-disabled,
.p-component:disabled {
  opacity: 0.8;
}

.p-dropdown {
  min-width: 200px;
}
</style>